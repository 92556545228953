
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlayerLayout',

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup() {
    /**
     * Метод для обработки события "@completed" общего для всех атомов
     * Может применяться для:
     *  - отправка аналитики
     *  - взаимодействие с интерфейсом
     */
    const onCompleted = () => {
      console.log('Implement onCompleted method');
    };

    return {
      onCompleted,
    };
  },
});
